(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("VueCustomElement"), require("vue"), require("axios"));
	else if(typeof define === 'function' && define.amd)
		define(["VueCustomElement", , "axios"], factory);
	else if(typeof exports === 'object')
		exports["loyalty-contact-form"] = factory(require("VueCustomElement"), require("vue"), require("axios"));
	else
		root["loyalty-contact-form"] = factory(root["VueCustomElement"], root["Vue"], root["axios"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__2476__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_cebe__) {
return 