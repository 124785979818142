<template>
  <div class="mdc-layout-grid loyalty-contact-form">
    <div class="mdc-layout-grid__inner">
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-phone  mdc-layout-grid__cell--span-8-tablet  mdc-layout-grid__cell--span-6-desktop content sx">
        <h2 class="uppercase bold">
          Info
        </h2>

        <div class="info-grid">
          <p>
            Per maggiori informazioni puoi chiamare <br />il numero verde Q-Cumber.
          </p>
          <img src="../../../../assets/n-verde-q-cumber.png" alt="800973370">
        </div>
      </div>
      <div class="mdc-layout-grid__cell mdc-layout-grid__cell--span-12-phone  mdc-layout-grid__cell--span-8-tablet  mdc-layout-grid__cell--span-6-desktop content dx">
        <loading-box
          v-if="loading"
          class="loading-box">
        </loading-box>

        <h2 class="uppercase bold">
          Restiamo <br> in contatto
        </h2>

        <div class="form">
          <h2 class="error" v-if="formError">
            {{formError}}
          </h2>
          <h2 class="success" v-if="success">
            {{successMessage}}
          </h2>

          <LoyaltyMdcTextField
            dark
            ref="nameInput"
            class="nameInput"
            :placeholder="nameLabel"
            v-model="name"
            fullwidth
          >
          </LoyaltyMdcTextField>

          <LoyaltyMdcTextField
            dark
            ref="emailInput"
            class="emailInput"
            type="email"
            :placeholder="emailLabel"
            v-model="email"
            fullwidth
          >
          </LoyaltyMdcTextField>

          <LoyaltyMdcTextarea
            dark
            ref="messageInput"
            class="messageInput"
            type="text"
            :placeholder="messageLabel"
            v-model="message"
            rows="5"
            fullwidth
          >
          </LoyaltyMdcTextarea>

          <LoyaltyMdcButton
            class="sendButton"
            @click="sendContact"
            :disabled="!formValid"
            dark
            >
            {{sendMessage}}
          </LoyaltyMdcButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import xhrPostMixin from '@paiuolo/pai-vue-mixins/mixins/xhrPostMixin'
import ErrorResponseMixin from '@paiuolo/pai-vue-mixins/mixins/ErrorResponseMixin'
import LoadingElementMixin from '@paiuolo/pai-vue-mixins/mixins/LoadingElementMixin'

import LoyaltyMdcTextField from '../../../elements/input/loyalty/LoyaltyMdcTextField'
import LoyaltyMdcTextarea from '../../../elements/input/loyalty/LoyaltyMdcTextarea'
import LoyaltyMdcButton from '../../../elements/button/loyalty/LoyaltyMdcButton'

import LoadingBox from '../../../elements/loader/LoadingBox'


export default {
  name: 'LoyaltyContactForm',
  mixins: [
    xhrPostMixin,
    ErrorResponseMixin,
    LoadingElementMixin
  ],
  components: {
    LoyaltyMdcTextField,
    LoyaltyMdcTextarea,
    LoyaltyMdcButton,
    LoadingBox
  },
  props: {
    contactUrl: {
      type: String,
      default: '/api/v1/contact/'
    }
  },
  data () {
    return {
      xhrPostUrl: this.contactUrl,
      xhrPostOptions: {
        withCredentials: true
      },
      sendMessage: this.gettext('Send'),
      successMessage: this.gettext('Thank you!'),
      nameLabel: this.gettext('Name'),
      emailLabel: 'E-mail',
      messageLabel: this.gettext('Message'),
      name: null,
      email: null,
      message: null,
      formError: null,
      success: false
    }
  },
  computed: {
    formValid () {
      let fieldsNotBlank = this.name !== null && this.email !== null && this.message !== null
      return fieldsNotBlank
    }
  },
  mounted () {
    this.$on('xhrPostResponse', () => {
      this.clear()
      this.success = true
      this.stopLoading()
    })
    this.$on('xhrPostError', (error) => {
      this.success = false
      this.formError = this.parseError(error)
      this.stopLoading()
    })
  },
  methods: {
    clear () {
      this.$refs.nameInput.clear()
      this.$refs.emailInput.clear()
      this.$refs.messageInput.clear()
      this.success = false
      this.formError = null
    },
    sendContact () {
      let fieldsValid = this.$refs.emailInput.valid && this.$refs.nameInput.valid && this.$refs.messageInput.valid
      if (this.formValid && fieldsValid) {
        this.startLoading()
        
        this.xhrPostParams = {
          name: this.name,
          email: this.email,
          message: this.message
        }
        this.performXhrPost()
      } else {
        this.success = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

$mdc-layout-grid-default-gutter: ( desktop: 0px, tablet: 0px, phone: 0px );
$mdc-layout-grid-default-margin: ( desktop: 0px, tablet: 0px, phone: 0px );

@import "@material/layout-grid/mdc-layout-grid";

.loyalty-contact-form {

  .mdc-layout-grid__cell{
    min-height: 400px;
  }
  .mdc-layout-grid__cell.content.sx {
    background-color: #778C93;
    color: #fff
  }
  .mdc-layout-grid__cell.content.dx {
    position: relative;
    background-color: #627B84;
    color: #fff;
  }
  .mdc-layout-grid__cell.content{
    padding: 48px;
    padding-bottom: 48px;
    box-sizing: border-box;
  }
  .mdc-layout-grid__cell {
    p {
      margin-bottom: 24px
    }
    img {
      display: block;
      margin: 16px 0;
    }
    h2 {
      font-size: 45px;
      line-height: 48px;
    }

    .sendButton {
      margin-top: 16px;
    }
    .form {
      position: relative;
    }
    .error {
      color: $error;
    }
    .success {
      color: $selected;
    }
  }

}
</style>
