<template>
  <div class="loadingBox"
    :class="{transparent: transparent, dark: dark}"
    v-show="show">
    <!-- @slot Animated slot
    
    Slotted content must have class="animated"
    -->
    <heart-beat>
      <slot>
        <img v-if="dark"
          src="../../../assets/elements/loader/loader-white.png" alt="loading.." class="animated"/>
        <img v-else
          src="../../../assets/elements/loader/loader.png" alt="loading.." class="animated"/>
      </slot>
    </heart-beat>
  </div>
</template>

<script>
import HeartBeat from '../../animations/HeartBeat'

export default {
  name: 'LoadingBox',
  components: {
    HeartBeat
  },
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/flexbox";

.loadingBox {
  @include flexbox();
  @include flex-direction(row);

  align-items: center;
  justify-content: center;

  position: absolute;
  z-index: 999;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.65);

  > * {
    position: relative;
    text-align: center;
  }
}
.loadingBox.transparent {
  background-color: transparent;
}
</style>
