import Vue from 'vue'
import axios from 'axios'
import VueCustomElement from 'vue-custom-element'

import VueGettext from '@paiuolo/pai-vue-gettext/plugins/VueGettext'

import LoyaltyContactForm from './components/entities/loyalty/contact/LoyaltyContactForm'


axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

Vue.prototype.$http = axios
Vue.config.productionTip = false

Vue.use(VueCustomElement)
Vue.use(VueGettext)

Vue.customElement('loyalty-contact-form', LoyaltyContactForm)
