<template>
  <div ref="inputContainer"
    class="mdc-text-field text-field mdc-text-field--with-trailing-icon mdc-text-field--fullwidth mdcTextField"
    :class="{big: big, dark: dark}"
    >
    <i ref="icon"
      class="material-icons mdc-text-field__icon" v-html="icon" tabindex="0" role="button" @click="clear"></i>
    <input ref="input"
      class="mdc-text-field__input"
      :type="type"
      :aria-label="label"
      @input='evt=>value=evt.target.value'
      :placeholder="placeholder"
      :name="name"
      :required="required"
      :minlength="minlength"
      :tabindex="tabindex"
      @keyup.enter="$emit('submitted')"
      v-model="value">
  </div>
</template>

<script>
import MdcTextFieldMixin from '../MdcTextFieldMixin'

export default {
  name: 'LoyaltyMdcTextField',
  mixins: [
    MdcTextFieldMixin
  ],
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

@import "@material/textfield/mdc-text-field";
@import "@material/textfield/icon/mdc-text-field-icon";

.mdc-text-field {
  @include mdc-text-field-icon-color($selected);
  @include mdc-text-field-ink-color(#111);
  @include mdc-text-field-label-color($mdc-theme-secondary);

  @include mdc-text-field-bottom-line-color($mdc-theme-secondary);
  @include mdc-text-field-hover-bottom-line-color($mdc-theme-secondary);
  
  @include mdc-text-field-caret-color($selected);

  .mdc-text-field__input {
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
  .mdc-text-field__input:focus {
    @include mdc-text-field-bottom-line-color($selected);
  }
}
.mdc-text-field.mdc-text-field--focused {
  @include mdc-text-field-bottom-line-color($selected);
}

.mdc-text-field.mdc-text-field--invalid {
  @include mdc-text-field-icon-color(#f00);
  @include mdc-text-field-bottom-line-color(#f00);
  @include mdc-text-field-ink-color(#f00);
}

.mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
  padding-left: 12px;
}

.dark.mdc-text-field {
  @include mdc-text-field-icon-color(#fff);
  @include mdc-text-field-ink-color(#fff);
  @include mdc-text-field-label-color(#fff);

  @include mdc-text-field-bottom-line-color(#fff);
  @include mdc-text-field-hover-bottom-line-color(#fff);

  .mdc-text-field__input:focus {
    @include mdc-text-field-bottom-line-color($selected);
  }
}
.dark.mdc-text-field.mdc-text-field--focused {
  @include mdc-text-field-icon-color($selected);
  @include mdc-text-field-bottom-line-color($selected);
  @include mdc-text-field-hover-bottom-line-color($selected);
}

.big.mdc-text-field {
  .mdc-text-field__input {
    font-size: 2em;
  }
}
</style>
