<template>
  <button class="mdc-button loyalty-mdc-button"
    ref="button"
    :style="customStyle"
    v-on:click="clicked"
    :class="{ dark: dark }"
    :disabled="disabled">

    <span class="mdc-button__label">
      <slot></slot>
    </span>
  </button>
</template>

<script>
import ButtonMixin from '../ButtonMixin'

// import {MDCRipple} from '@material/ripple'
import { MDCRipple } from '@material/ripple/dist/mdc.ripple'

export default {
  name: 'LoyaltyMdcButton',
  mixins: [
    ButtonMixin
  ],
  props: {
    fill: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    },
    big: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      ripple: null
    }
  },
  mounted () {
    this.ripple = new MDCRipple(this.$refs.button)
  },
  computed: {
    customStyle () {
      let obj = {}
      if (this.fill) {
        obj.width = '100%'
        obj.height = '100%'
      } else {
        if (this.big) {
          obj.padding = '0 24px'
          obj['font-size'] = '22px'
          obj['height'] = '52px'
        }
      }
      return obj
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

@import "@material/button/mdc-button";

.mdc-button {
  @include mdc-button-ink-color($selected);
  @include mdc-states($selected);

  border-radius: 6px;
  font-weight: bold;
  height: 48px;
  padding: 0 16px;
  border: 2px solid $selected;
}

.mdc-button:hover {
  border: 2px solid $selected;
}

.mdc-button.dark {
  border: 2px solid #fff;
  @include mdc-button-ink-color(#fff);
}
.mdc-button.dark:hover {
  border: 2px solid $selected;
}

</style>
