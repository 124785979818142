import InputMixin from './InputMixin'
import {MDCTextField, MDCTextFieldIcon} from '@material/textfield/dist/mdc.textfield'
// import {MDCTextField} from '@material/textfield'
// import {MDCTextFieldIcon} from '@material/textfield/icon'

export default {
  mixins: [
    InputMixin
  ],
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    minlength: {
      type: [String, Number],
      default: 0
    },
    mdcIcon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      textField: null,
      textFieldIcon: null
    }
  },
  computed: {
    icon () {
      if (this.value && this.value !== '') {
        return 'clear'
      } else {
        return this.mdcIcon
      }
    }
  },
  mounted () {
    // this.valid = this.textField.foundation_.isValid()

    this.textField = new MDCTextField(this.$refs.inputContainer)
    if (this.$refs.icon) {
      this.textFieldIcon = new MDCTextFieldIcon(this.$refs.icon)
    }
  }
}
