t<template>
  <div ref="inputContainer"
    class="mdc-text-field mdc-text-field--textarea"
	:class="{dark: dark}"
    >
    <textarea ref="input"
      class="mdc-text-field__input"
      :name="name"
      :rows="rows"
      :cols="cols"
      :disabled="disabled"
      :placeholder="placeholder"
      :required="required"
      @input='evt=>value=evt.target.value'
      v-model="value"
      ></textarea>
  </div>
</template>

<script>
import MdcTextFieldMixin from '../MdcTextFieldMixin'

export default {
  name: 'LoyaltyMdcTextarea',
  mixins: [
    MdcTextFieldMixin
  ],
  props: {
    rows: {
      type: [String, Number],
      default: 8
    },
    cols: {
      type: [String, Number],
      default: 30
    },
    dark: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    clear () {
      this.value = ''
      this.$refs.input.value = ''
      this.$refs.input.focus()
      this.$emit('clear', null)
      this.$emit('input', null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/loyalty.theme";

@import "@material/textfield/mdc-text-field";
@import "@material/textfield/icon/mdc-text-field-icon";

.mdc-text-field--textarea {
  @include mdc-text-field-ink-color(#111);
  @include mdc-text-field-label-color($mdc-theme-secondary);
  @include mdc-text-field-caret-color($selected);

  @include mdc-text-field-bottom-line-color($mdc-theme-secondary);
  @include mdc-text-field-hover-bottom-line-color($selected);

  display: inline-block;
  display: inline-flex;
  box-sizing: border-box;
  overflow: hidden;
  border: none;
  border-radius: 0;
  padding: 16px 0 0 0;
  width: 100%;

  .mdc-text-field__input {
    border: none;
    border-radius: 0;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding: 0 12px;
    border-color: $selected;
  }
}
.mdc-text-field--textarea.mdc-text-field--focused {

}

.dark.mdc-text-field--textarea {
  @include mdc-text-field-ink-color(#fff);
  @include mdc-text-field-label-color(#fff);
  @include mdc-text-field-caret-color(#fff);

  .mdc-text-field__input {
    border-color: #fff;
  }
}
.dark.mdc-text-field--textarea.mdc-text-field--focused {
  @include mdc-text-field-caret-color($selected);

  .mdc-text-field__input {
    border-color: $selected;
  }
}
</style>
